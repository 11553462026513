import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import NavBar from './components/navbar';
import Footer from './components/footer';

import Home from './pages/homepage';
import ProjectPage from './pages/projectpage';
import NotFoundPage from './pages/notfoundpage';

import "./styles/page.css"
  
function App() {
  return (
      <Router>
          <NavBar/>
          <div className="page">
            <Routes>
              <Route path="/" exact element={<Home/>} />
              <Route path="/projects" exact element={<ProjectPage/>} />
              {/*<Route path="/contact" exact element={<Contact/>} />*/}
              <Route path="*" exact element={<NotFoundPage/>} />
            </Routes>
          </div>
          <Footer/>
      </Router>
  );
}
  
export default App;
