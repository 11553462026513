import React from 'react';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';

import '../styles/project.css';

const Project = props => {
    const [modalShow, setModalShow] = React.useState(false);
    
    return (
        <>
        <Card className="projects-card background-dark-grey">
            <Card.Img variant="top" src={require('../assets/'+props.project.img+'.webp')}/>
            <Card.Body>
                <Card.Title>{props.project.title}</Card.Title>
                <Button onClick={() => setModalShow(true)} className="background-purple border-none">Show details</Button>
            </Card.Body>
        </Card>

        <Modal show={modalShow} onHide={() => setModalShow(false)} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Project Details
                </Modal.Title>
                <Button onClick={() => setModalShow(false)} className="background-purple border-none">Close</Button>
            </Modal.Header>
            <Modal.Body>
                <h3>{props.project.title}</h3>
                <img src={require('../assets/'+props.project.img+'.webp')} className="width-80 width-lg-95" alt={props.project.title}/>
                <div className="projects-modal-caption">
                    <p><i>{props.project.caption}</i></p>
                </div>
                <div className="projects-modal-body">
                    <h4>Description</h4>
                    <p>{props.project.desc}</p>
                    <h4>Technologies</h4>
                    <h6>{props.project.skills}</h6>
                </div>
            </Modal.Body>
        </Modal>
        </>
)}

export default Project;