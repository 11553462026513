import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import '../styles/footer.css';


const Footer = () => {
    return (
        <>
        <Container fluid className="footer">
            <Row className="justify-content-between">
                <Col xs={8} className="text-left">
                    <p>Copyright © 2023 Alexandra Cornide Huber</p>
                </Col>
                <Col xs={4} className="text-right">
                    <a href="https://www.github.com/alexchuber/"><b>GitHub</b></a>
                    <a href="https://www.linkedin.com/in/alexchuber/"><b>LinkedIn</b></a>
                    <a href="https://mail.google.com/mail/?view=cm&fs=1&to=alex13huber@gmail.com"><b>Email</b></a>
                </Col>
            </Row>
        </Container>
        </>
    );
}

export default Footer;