import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import me from "../assets/me2.png";

import '../styles/homepage.css';


const Home = () => {

    return (
      <Container fluid className="full-page-padding">
        <Row className="align-content-center justify-content-around flex-direction-reverse-lg">
          <Col xs={12} xl={6} className="align-self-center">
            <h1>Hey there!</h1>
            <Button href="/projects" variant="info" className="background-purple border-none width-40 btn-font-size">
              See my projects
            </Button>
          </Col>
          <Col xs={12} xl={6} className="align-self-center">
            <img src={me} width="80%" height="auto" alt=""/>
          </Col>
        </Row>
    </Container>
    );
}

export default Home;