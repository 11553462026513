import React from 'react';

const NotFoundPage = () => {

    return (
        <h1 style={{padding: "30vh 30vw"}}>
            Page not found :/
        </h1>
    );
}

export default NotFoundPage;