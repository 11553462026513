import React from 'react';

import Project from './project';

import Container from 'react-bootstrap/Container';

import '../styles/projectgallery.css';

const projectToJSON = (t, d, s, i, c) => {
    const json = {
        title: t,
        desc: d,
        skills: s,
        img: i,
        caption: c
    };
    return json;
}

const projects = [
        projectToJSON(
            "'Shark Den'",
            "This was a project for my Creating Mobile Games class. It introduced the fundamentals of game design, particularly as they apply to 2D platformers. While the project was more focused on art and animations (which I am not keen on), I did edit some of the scripts our professor gave in prefabs. The game has infinitely many levels!",
            "Unity, C#",
            "sharkden",
            "I'm a programmer, not an artist-- be gentle!"
            ),
        projectToJSON(
            "'ClassHub'",
            "This was a group project made for our Introduction to Software Engineering class. We practiced using web technologies and Agile methods-- specifically, the MERN stack and Scrum framework. The project had the functionality of a forum where users could create, manage, and selectively share pages of user-made lesson content.",
            "JavaScript, React, Node, MongoDB, CircleCI",
            "classhub"
            ),
        projectToJSON(
            "Transpiler",
            "This is a compiler I created with a partner for our Programming Language Concepts class. The (unnamed) language we designed had built-in image manipulation syntax, among other standard features of programming languages, that transpiled into Java. Topics covered in our project include regular expressions, context-free grammars, error handling, top-down parsing, abstract syntax trees, visitor design pattern, and the steps of compilation such as lexing, parsing, type checking, etc.",
            "Java",
            "compiler",
            "A festive alligator, created by the highlighted text written in our new language"
            ),
        projectToJSON(
            "Procedural Terrain Generator",
            "I made this as part of my Introduction to Digital Arts & Sciences class. The terrain is generated by reading visual data from a heightmap (perlin noise), which is a greyscale image depicting an aerial view of the terrain. Lighter areas of the heightmap represent higher elevations; darker parts represent lower. So, the darkness of a pixel in the heightmap corresponds to some vertex's elevation in the 3D terrain.",
            "Java (Processing)",
            "proceduralterrain"
            ),
        projectToJSON(
            "Cache Simulator",
            "For my Computer Architecture class, I made a cache simulator to compare different cache configurations. A user can specify the number of sets, the number of blocks per set, the size of each block, and the replacement policy to use (First In First Out or Least Recently Used.) Our professor provided us with a trace file-- a record of accessed memory addresses-- to simulate user behavior.",
            "C++",
            "cachesimulator"
            ),
        projectToJSON(
            "First 'good first issue' Fix",
            "I tried my hand at an issue from an open-source library, google/benchmark, to better understand source control management and professional codebases. The feature request I implemented was for a new flag, benchmark-time-unit. Doing so taught me how to navigate new code, what typical coding conventions look like, and how to follow best practices. Unfortunately, someone else beat me to the pull request. Lesson learned!",
            "C++, Git, GitHub",
            "googlebenchmark",
            "A screenshot from a demonstration I gave of the feature, benchmark-time-unit"
            ),
        projectToJSON(
            "Minesweeper Recreation",
            "As one of my first experiences with game systems programming-- really, programming in general-- this Minesweeper recreation was made for my programming fundamentals II course. I got to practice using a graphics library, the event-listener pattern, lambdas, UI structures, and more. In order to make development easier, I added a \"debug mode\" that revealed mines and a feature that allowed for user-created boards.",
            "C++",
            "minesweeper"
            ),
        projectToJSON(
            "Transaction Classifier",
            "I put together this little text classifier to predict categories of CC transactions. Since it was my first dabble in Python and ML, the modules did the heavy lifting for me. I first cleaned & vectorized the data, then looked for any skew or correlations between columns. The Stochastic Gradient Descent classifier had the best performance of the 10 models I tried & tuned. That's the one you see here. NOTE: This is an awful representation of how I code! I promise I'm well-acquainted with OOP principles-- I was just messing around with different features.",
            "Python",
            "textclassifier"
            ),
        projectToJSON(
            "Random Terrain Generator",
            "I made this as part of my Introduction to Digital Arts & Sciences class. The program randomly generates terrain in the same way that a random walk algoritm works. First, the terrain is represented by either squares or hexagons. Each \"step\", or shape, that the walker takes must be directly adjacent to the last \"step\". The direction of the next step-- or which side of the square/hexagon it will be adjacent to-- is decided randomly. Multiple steps over the same spot result in different terrain colors. For example, a white shape indicates a high number of steps taken there.",
            "Java (Processing)",
            "randomterrain"
            ),
        projectToJSON(
            "Stable Matchmaker",
            "This was a group project I did for our Data Structures & Algorithms class. Our goal was to find a stable matching between two equally-sized sets of participants using the Gale-Shapley algorithm. Each participant ranks their suitors-- the other pool of other participants-- and is then paired with the highest-ranked suitor available. We compared how long the algorithm took using an adjacency matrix and an adjacency list as containers. Since the size of our data was always N^2, there was no difference.",
            "C++, Qt",
            "matchmakers"
            ),
        projectToJSON(
            "Animations",
            "I made this as part of my Introduction to Digital Arts & Sciences class. In order to animate a model, we linearly interpolated the mesh's vertex positions from one keyframe to the next. Everything shown above is moving (or would be moving, if it weren't a picture!)",
            "Java (Processing)",
            "animation"
            ),
        projectToJSON(
            "Self-balancing BST",
            "This was a project for my Data Structures & Algorithms class. We implemented a self-balancing binary search tree-- specifically, an AVL tree-- to track student IDs. AVL Trees use rotations to keep itself balanced. If an insertion results in one side being \"heavier\" than the other, the offending subtree will rearrange its top levels to balance itself.",
            "C++",
            "avltree"
            ),
        projectToJSON(
            "This website!",
            "This is a continual WIP! As of recent (11/3/22), I've been trying to figure out how to optimize the site's load times. This has led me down the rabbit hole of image optimization. So far, most of my images are able to be served in different resolutions and nextgen formats.",
            "JavaScript, React, Figma, React Bootstrap, AWS",
            "thiswebsite"
            )
];


const ProjectList = props => {

    const showProjects = () => {
        return projects.map(function(proj, i) {
            return <Project project={proj} key={i} />
        })
    }

    return (
        <Container fluid className="projectgallery-container">
            {showProjects()}
        </Container>
    );
}

export default ProjectList;