import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Offcanvas from 'react-bootstrap/Offcanvas';

import logo from '../assets/Name.png';

import '../styles/navbar.css';

/*  
    TODO
    -Fix responsiveness of logo
*/

const NavBar = () => {
    return (
      <Navbar expand="sm" variant="dark" sticky="top" className="background-grey">
        <Container fluid>
          <Navbar.Brand href="/"><img src={logo} width="300" height="50" className="navbar-brand-image"alt=""/></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <Nav>
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/projects">Projects</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
}

export default NavBar;