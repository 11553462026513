import React from 'react';

import ProjectList from '../components/projectlist';

import '../styles/projectpage.css';


const ProjectPage = () => {

    return (
        <div className="projects">
            <h1>Recent Projects</h1>
            <br/>
            <ProjectList/>
        </div>
    );
}

export default ProjectPage;
